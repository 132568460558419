import React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import NtText from "../../components/NtText";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtDashboardItem from "../components/NtDashboardItem";
import routes from "../../navigation/routes";
import NtDashboardExtension from "../components/NtDashboardExtension";
import Logger from "../utils/Logger";

class DashboardController extends React.Component {
  state = {};

  options = [
    {
      icon: "file-upload-outline",
      title: "Upload Extensions",
      description: "Create extensions from .csv",
      action: () => {
        this.props.navigation.navigate(routes.upload);
      },
    },
    {
      icon: "phone",
      title: "Create Extension",
      description: "Create single extension",
      action: () => {
        this.props.navigation.navigate(routes.extensioncreate);
      },
    },
    {
      icon: "newspaper-variant-outline",
      title: "Extension Report",
      description: "View extension report",
      action: () => {
        this.props.navigation.navigate(routes.extensionreport);
      },
    },
    {
      icon: "server",
      title: "Gateway Report",
      description: "View gateway report",
      action: () => {
        this.props.navigation.navigate(routes.gatewayreport);
      },
    },
    {
      icon: "link",
      title: "Links",
      description: "Shortcut Links",
      action: () => {
        this.props.navigation.navigate(routes.links);
      },
    },
    {
      icon: "dialpad",
      title: "App Access",
      description: "Configure PIN Access for apps",
      action: () => {
        this.props.navigation.navigate(routes.appaccess);
      },
    },
    {
      icon: "asterisk",
      title: "Feature Codes",
      description: "Feature code list",
      action: () => {
        this.props.navigation.navigate(routes.featurecode);
      },
    },
  ];

  renderRow = (item, index) => {
    return (
      <NtDashboardItem
        containerStyle={{ marginRight: 20, marginTop: 20, flex: 1 }}
        key={item.title}
        option={item}
        onPress={() => {
          item.action();
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Dashboard"}
        showFooter={true}
        showNavigation={false}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: 20,
          }}
        >
          <NtDashboardExtension />
        </View>

        <FlatList
          style={{ flex: 1 }}
          data={this.options}
          numColumns={3}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => this.renderRow(item, index)}
          onEndReachedThreshold={0.5}
        />
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default DashboardController;
