const tariffs = [
  {
    id: 1121,
    code: "DNLUSPP171027",
    name: "Domestic",
  },
  {
    id: 1125,
    code: "DNLAZPP180115",
    name: "International",
  },
  {
    id: 649,
    code: "Earthlink-18YY",
    name: "1800s",
  },
  {
    id: 37,
    code: "250-Free-411",
    name: "Free 411",
  },
];

export default tariffs;
