import React from "react";
import { View, StyleSheet, Image, ScrollView } from "react-native";
import colors from "../../config/colors";
import NtTextInput from "../../components/NtTextInput";
import { authenticate, authenticateRequestCode } from "../../api/auth";
import { showToast } from "../../common/utils/ControllerUtils";
import BaseController from "../../common/base/BaseController";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtFormButtonNext from "../../common/components/NtFormButtonNext";
import NtLoginHADropdownItem from "../components/NtLoginHADropdownItem";
import NtLoginHALoginButton from "../components/NtLoginHALoginButton";
import NtModal from "../../components/NtModal";
import NtLoginHALocationPicker from "../components/NtLoginHALocationPicker";
import routes from "../../navigation/routes";
import Logger from "../../common/utils/Logger";

class AuthController extends BaseController {
  static sHEIMDALLWEB_USER_CREDENTIALS = "sHEIMDALLWEB_USER_CREDENTIALS";

  loginTypes = [
    {
      id: "pin",
      icon: "dialpad",
      title: "Pin",
    },
    {
      id: "password",
      icon: "form-textbox-password",
      title: "Password",
    },
  ];

  state = {
    username: "",
    password: "",
    location: null,
    loading: false,
    type: this.loginTypes[0],
  };

  locationPickerRef = React.createRef();

  componentDidMount() {
    document.title = "NOOZ.AI | Log In";
  }

  handleLogin = async () => {
    if (!this.isFieldsRequired()) {
      showToast("Oops...", "All fields required.", "error");
      return;
    }

    this.setState({ isLoading: true });

    if (this.state.type?.id == "pin") {
      const response = await authenticateRequestCode(
        `${this.state.username}@${this.state.location?.domain}`
      );
      if (response.ok) {
        const params = {
          extension: this.state.username,
        };
        this.props.navigation.navigate(routes.authcode, params);
      } else {
        if (response.status == 404) {
          showToast(
            "Oops...",
            "Login using Pin is not allowed for this extension. Please use passsword instead"
          );
        } else if (response.status == 409) {
          showToast("Oops...", "Unable to find extension.");
        } else {
          showToast(
            "Oops...",
            "Unable to process request. Please try again later."
          );
        }
      }
    } else if (this.state.type?.id == "password") {
      const response = await authenticate(
        `${this.state.username}@${this.state.location?.domain}`,
        this.state.password
      );

      if (response.ok) {
        this.setState({ username: "", password: "", location: null });
        this.context.updateUser({
          ...response.data,
          authenticated: true,
          location: this.state.location,
        });
      } else {
        showToast("Oops...", "Invalid username/password convination", "error");
      }
    }

    this.setState({ loading: false });
  };

  isFieldsRequired = () => {
    if (this.state.type?.id == "pin") {
      return this.state.username && this.state.location;
    } else if (this.state.type?.id == "password") {
      return this.state.username && this.state.password && this.state.location;
    }
    return false;
  };

  renderLocationPickerModal = () => {
    return (
      <NtModal
        ref={this.locationPickerRef}
        renderBody={
          <NtLoginHALocationPicker
            apptype={"maritime"}
            value={this.state.location?.name}
            onPress={(location) => {
              this.context.updateUser({
                ...this.context.user,
                location: location,
              });
              this.setState({ location: location });
              this.locationPickerRef.current?.dismiss();
            }}
          />
        }
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        showNavigation={false}
        showFooter={true}
        renderTitleView={<View />}
      >
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              maxWidth: 550,
              minWidth: 400,
              backgroundColor: colors.lighestGray,
              padding: 35,
              borderRadius: 10,
            }}
          >
            <NtText style={[styles.title]}>Login</NtText>
            <NtText style={[styles.subtitle, { marginTop: 6 }]}>
              Please sign in to continue
            </NtText>

            <NtTextInput
              textStyle={{ flex: 1 }}
              containerStyle={[styles.input, { marginTop: 30 }]}
              placeholder={"Extension including prefix"}
              placeholderTextColor={colors.lighGray}
              value={this.state.username}
              autoCorrect={false}
              autoCapitalize="none"
              onChangeText={(text) => this.setState({ username: text })}
            />

            {this.state.type?.id == "password" && (
              <NtTextInput
                textStyle={{ flex: 1 }}
                containerStyle={[styles.input, { marginTop: 15 }]}
                placeholder={"Password"}
                placeholderTextColor={colors.lighGray}
                showEye={true}
                value={this.state.password}
                secureTextEntry={true}
                autoCorrect={false}
                autoCapitalize="none"
                onChangeText={(text) => this.setState({ password: text })}
              />
            )}

            <NtLoginHADropdownItem
              containerStyle={[styles.input, { marginTop: 15 }]}
              placeholder={"Select Location"}
              value={this.state.location?.name}
              onPress={() => {
                this.locationPickerRef.current?.show();
              }}
            />
            <View style={{ marginTop: 35, alignItems: "flex-end" }}>
              <NtLoginHALoginButton
                onPress={
                  this.isFieldsRequired()
                    ? () => {
                        this.handleLogin();
                      }
                    : null
                }
                onOptionPress={(selection) => {
                  this.setState({ type: selection });
                }}
                loading={this.state.loading}
                options={this.loginTypes}
                selected={this.state.type}
              />
            </View>
          </View>
        </View>
        {this.renderLocationPickerModal()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%",
    height: 45,
    backgroundColor: "white",
    borderRadius: 12,
  },
  title: {
    fontSize: 44,
    fontWeight: "bold",
    color: colors.darkest,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "#f2c066",
  },
  info: {
    textAlign: "center",
    color: colors.darker,
  },
});

export default AuthController;
