import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";

function NtLinkHeaderItem({ title, containerStyle }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={styles.title}>{title}</NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.darkest,
    fontSize: 19,
    fontWeight: "500",
  },
});

export default NtLinkHeaderItem;
