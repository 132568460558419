import React, { useEffect, useState, useContext } from "react";
import { View, StyleSheet, ScrollView, NativeModules } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import Logger from "../../common/utils/Logger";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { fetchLocations } from "../../api/location";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtDevider from "../../components/NtDevider";
import UserContext from "../../context/UserContext";
const { NettalkModule } = NativeModules;

function NtLocationPicker({ onPress, value }) {
  const [locations, setLocations] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const context = useContext(UserContext);

  const handleLocationFetch = async () => {
    setLoading(true);
    setError(null);

    let devLocation = {};

    const response = await fetchLocations();

    if (response.ok) {
      setLocations(response.data);
    } else {
      setError("Unable to load locations.");
    }

    setLoading(false);
  };

  useEffect(() => {
    handleLocationFetch();
  }, []);

  const renderRow = (item, index) => {
    return (
      <NtTouchableEffect
        key={index}
        onPress={() => {
          if (onPress) {
            onPress(item);
          }
        }}
      >
        <View
          style={{
            paddingTop: 15,
            paddingBottom: 15,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 1 }}>
            <NtText style={styles.rowTitle}>{item.name}</NtText>
            <NtText style={styles.rowSubtitle}>{item.code}</NtText>
          </View>

          {item.name == value && (
            <NtMaterialIcon name="check" color={colors.darkest} />
          )}
        </View>

        <NtDevider />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <NtText style={[styles.title, { marginBottom: 20 }]}>
          Select Location
        </NtText>

        {locations?.map((each, index) => renderRow(each, index))}
        <NtText style={[styles.error, { marginTop: 6 }]}>{error}</NtText>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 250,
    padding: 15,
    maxHeight: 450,
  },

  title: {
    color: colors.darkest,
    fontSize: 17,
    fontWeight: "700",
  },
  rowTitle: {
    color: colors.darkest,
    fontSize: 14,
    fontWeight: "600",
  },
  rowSubtitle: {
    color: colors.darkest,
    fontSize: 12,
    fontWeight: "400",
  },
  error: {
    color: "red",
    fontSize: 12,
    fontWeight: "400",
  },
});

export default NtLocationPicker;
