import Logger from "../common/utils/Logger";
import settings from "../config/settings";
import client from "./client";

export const fetchExtensions = (
  domain,
  page = 1,
  pageSize = 25,
  prefix = null
) => {
  let url = `/extension/domain/${domain}?page=${page}&pagesize=${pageSize}`;

  if (prefix) {
    url += `&extensionPrefix=${prefix}`;
  }

  return client.get(url);
};

export const fetchExtensionByNumber = (extension, domain) => {
  return client.get(`/extension/domain/${domain}/${extension}`);
};

export const createExtension = async (
  domain,
  extension,
  password,
  phone = "",
  name = "",
  description = "",
  title = "",
  location = ""
) => {
  return new Promise(async (resolve, reject) => {
    //body
    let extensionBody = {
      extension: extension,
      password: password,
      caller_id_name: name,
      description: description,
      mobile_prefix: settings.mobileExtensionPrefix,
      physical_prefix: settings.physicalExtensionPrefix,
    };

    if (phone) {
      if (phone.startsWith("1")) {
        extensionBody.tn = phone.substring(1);
      } else {
        extensionBody.tn = phone;
      }
    }

    //Will create the extension...
    const extensionResponse = await client.post(
      `/extension/domain/${domain}/createfull`,
      extensionBody
    );

    if (!extensionResponse.ok) {
      Logger("Oops... Unable to create the extension", extensionResponse);
    }

    resolve(extensionResponse);
  });
};
