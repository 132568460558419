import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtLoginHADropdownItem({
  containerStyle,
  placeholder,
  value,
  textStyle,
  onPress,
  loading = false,
}) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      <NtText
        style={[
          textStyle,
          styles.text,
          {
            color: value ? colors.darkest : colors.lighGray,

            flex: 1,
          },
        ]}
      >
        {value || placeholder}
      </NtText>

      {!loading && (
        <NtMaterialIcon color={colors.darkest} name="chevron-down" />
      )}
      {loading && <NtActivityIndicator size="small" color={colors.darkest} />}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    color: colors.darkest,
    fontWeight: "400",
    fontSize: 14,
  },
});

export default NtLoginHADropdownItem;
