import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import Papa from "papaparse";
import Logger from "../../common/utils/Logger";
import NtFileButton from "../components/NtFileButton";
import colors from "../../config/colors";
import TextareaAutosize from "react-textarea-autosize";
import NtRateInputField from "../components/NtRateInputField";
import {
  handleRateCreate,
  handleRateRollbackSQL,
  handleRateUpdateSQL,
} from "../utils/RatesParser";
import NtButton from "../../components/NtButton";
import NtFileDownloadButton from "../components/NtFileDownloadButton";
import NtSelectButton from "../components/NtSelectButton";
import settings from "../../config/settings";
import BaseRateController from "./BaseRateController";
import NtModal from "../../components/NtModal";
import NtSelectionSheet from "../../components/NtSelectionSheet";
import providers from "../data/providers";
import tariffs from "../data/tariffs";
import { showToast } from "../../common/utils/ControllerUtils";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import moment from "moment";

import ReactCodeSinppet from "react-code-snippet";
// import { CopyBlock } from "react-code-blocks";

class RatesController extends BaseRateController {
  state = {
    file: null,
    loading: false,
    downloadingUpdate: false,
    downloadingBackup: false,
    rates: null,
    provider: { id: -1, name: "Select Provider" },
    tariff: { id: -1, name: "Select Tariff" },
  };

  providerModalRef = React.createRef();
  tariffModalRef = React.createRef();

  handleFileUpload = () => {};

  renderFileUploadButton = () => {
    return (
      <NtTouchableEffect onPress={() => {}}>
        <NtText>Upload .csv</NtText>
      </NtTouchableEffect>
    );
  };

  changeHandler = async (event) => {
    this.setState({ file: event.target.files[0] });
  };

  handleRateProcess = async () => {
    if (this.state.provider.id < 0 || this.state.tariff.id < 0) {
      showToast(
        "Oops...",
        "Please select Provider and Tariff to continue",
        "error"
      );
      return;
    }

    this.setState({ loading: true });
    const response = await handleRateCreate(
      this.state.file,
      this.state.provider,
      this.state.tariff
    );
    this.setState({
      loading: false,
      rates: response.data,
    });
  };

  handleFileDownload = async (type) => {
    let fileData = "";
    if (type === "update") {
      const response = await handleRateUpdateSQL(
        this.state.file,
        this.state.rates,
        this.state.provider
      );
      fileData = response.data;
    } else if (type === "rollback") {
      const response = await handleRateRollbackSQL(
        this.state.file,
        this.state.provider
      );
      fileData = response.data;
    }

    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `rate_${type}.sql`;
    link.href = url;
    link.click();
  };

  renderCSVInfo = () => {
    if (this.state.file) {
      return null;
    }

    return (
      <View
        style={{
          backgroundColor: colors.lighestGray,
          borderRadius: 8,
          padding: 20,
          marginTop: 20,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <NtMaterialIcon name={"information-outline"} color={colors.lighGray} />
        <NtText style={[styles.infoText, { marginLeft: 10 }]}>
          Please note, the .CSV columns MUST be named (case sensitive):{" "}
          <NtText style={{ fontWeight: "700", color: colors.dark }}>
            dialprefix
          </NtText>
          ,{" "}
          <NtText style={{ fontWeight: "700", color: colors.dark }}>
            inter
          </NtText>
          ,{" "}
          <NtText style={{ fontWeight: "700", color: colors.dark }}>
            intra
          </NtText>
        </NtText>
      </View>
    );
  };

  renderRunComand = () => {
    if (!this.state.rates) {
      return null;
    }

    return (
      <ReactCodeSinppet
        lang="bash"
        code={`mysql -h 172.16.111.17 -u user -p a2billing162nt2 < rate_update.sql`}
      ></ReactCodeSinppet>
    );
  };

  renderProviderModal = () => {
    return (
      <NtModal
        ref={this.providerModalRef}
        renderBody={
          <NtSelectionSheet
            options={providers}
            title={"Select Provider"}
            selected={this.state.provider.id}
            onSelection={(item) => {
              this.providerModalRef.current.dismiss();
              this.setState({
                provider: item,
              });
            }}
          />
        }
      />
    );
  };

  renderTariffModal = () => {
    return (
      <NtModal
        ref={this.tariffModalRef}
        renderBody={
          <NtSelectionSheet
            options={tariffs}
            title={"Select Tariff"}
            selected={this.state.tariff.id}
            onSelection={(item) => {
              this.tariffModalRef.current.dismiss();
              this.setState({
                tariff: item,
              });
            }}
          />
        }
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Rates"}
        showNavigation={false}
        showFooter={true}
      >
        <View
          style={{ marginTop: 20, flexDirection: "row", alignItems: "center" }}
        >
          <NtFileButton
            title={"Upload .csv"}
            icon={"file-upload-outline"}
            containerStyle={{ width: 130 }}
            onPress={() => {
              document.getElementById("selectFile").click();
            }}
          />

          {this.state.file && (
            <NtText style={[styles.fileTitle, { marginLeft: 6 }]}>
              {this.state.file.name}
            </NtText>
          )}
        </View>
        {this.renderCSVInfo()}
        {this.state.file && (
          <View
            style={{
              backgroundColor: colors.lighestGray,
              borderRadius: 8,
              marginTop: 20,
              padding: 20,
            }}
          >
            <NtText style={styles.calculationText}>
              Calculations are base on the following. Cost threshold:{" "}
              <NtText style={{ fontWeight: "800" }}>
                {settings.rateThreshold}
              </NtText>
              , multiplier:{" "}
              <NtText style={{ fontWeight: "800" }}>
                {settings.rateMultiplier}
              </NtText>
            </NtText>

            <NtSelectButton
              containerStyle={{ marginTop: 20 }}
              option={this.state.provider}
              onPress={() => {
                this.providerModalRef.current?.show();
              }}
            />
            <NtSelectButton
              containerStyle={{ marginTop: 10 }}
              option={this.state.tariff}
              onPress={() => {
                this.tariffModalRef.current?.show();
              }}
            />

            {!this.state.rates && (
              <NtFileButton
                containerStyle={{ marginTop: 20, width: 160 }}
                icon={"progress-check"}
                title={"Process Rates"}
                loading={this.state.loading}
                onPress={() => {
                  this.handleRateProcess();
                }}
              />
            )}

            {this.state.rates && (
              <View style={{ marginTop: 20, flexDirection: "row" }}>
                <NtFileDownloadButton
                  containerStyle={{ width: 200 }}
                  title={"Download Update"}
                  loading={this.state.downloadingUpdate}
                  onPress={() => {
                    this.handleFileDownload("update");
                  }}
                />
                <NtFileDownloadButton
                  containerStyle={{ marginLeft: 10, width: 200 }}
                  title={"Download Rollback"}
                  loading={this.state.downloadingBackup}
                  onPress={() => {
                    this.handleFileDownload("rollback");
                  }}
                />
              </View>
            )}

            {this.renderRunComand()}
          </View>
        )}

        <input
          id="selectFile"
          type="file"
          name="file"
          accept=".csv"
          style={{ display: "none" }}
          onChange={this.changeHandler}
        />
        {this.renderProviderModal()}
        {this.renderTariffModal()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  fileTitle: {
    color: colors.lighGray,
    fontSize: 13,
    fontWeight: "800",
  },

  calculationText: {
    fontSize: 14,
    fontWeight: "500",
    color: colors.dark,
  },

  infoText: {
    fontSize: 16,
    fontWeight: "400",
    color: colors.lighGray,
  },

  name: {
    fontSize: 14,
    borderColor: colors.lighGray,
    borderWidth: 1,
    borderRadius: 4,
    marginTop: 30,
    padding: 10,
    color: colors.lighGray,
    outlineColor: colors.dark,
    // outlineStyle: "none",
    resize: "none",
  },
});

export default RatesController;
