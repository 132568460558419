import React from "react";
import { View, StyleSheet } from "react-native";
import TextareaAutosize from "react-textarea-autosize";
import colors from "../../config/colors";
import NtText from "../../components/NtText";

function NtRateInputField({ containerStyle, onChange, value, placeholder }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtText>{placeholder}</NtText>
      <TextareaAutosize
        style={styles.input}
        draggable={false}
        minRows={1}
        maxRows={1}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          if (onchange) {
            onChange(e);
          }
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},

  input: {
    fontSize: 14,
    borderColor: colors.lighGray,
    borderWidth: 1,
    borderRadius: 4,
    padding: 10,
    color: colors.lighGray,
    outlineColor: colors.dark,
    // outlineStyle: "none",
    resize: "none",
  },
});

export default NtRateInputField;
