import React, { useRef, useContext } from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import NtModal from "../../components/NtModal";
import NtLocationPicker from "./NtLocationPicker";
import UserContext from "../../context/UserContext";

function NtLocationItem({ containerStyle, domain, onPress }) {
  const locationModalRef = useRef();
  const context = useContext(UserContext);

  const renderLocationModal = () => {
    return (
      <NtModal
        ref={locationModalRef}
        renderBody={
          <NtLocationPicker
            value={context.user?.location?.name}
            onPress={(location) => {
              context.updateUser({ ...context.user, location });
              locationModalRef.current?.dismiss();
            }}
          />
        }
      />
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={[styles.title, {}]}>Location</NtText>
      <NtTouchableEffect
        style={{
          marginLeft: 10,
          backgroundColor: "white",
          paddingLeft: 6,
          paddingRight: 6,
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: 30,
          flexDirection: "row",
          alignItems: "center",
        }}
        onPress={() => {
          locationModalRef.current.show();
        }}
      >
        <NtText style={styles.code}>{context.user?.location?.code}</NtText>
        <NtMaterialIcon name="chevron-down" color={colors.darkest} />
      </NtTouchableEffect>

      {renderLocationModal()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: "600",
    color: "white",
  },
  code: {
    fontSize: 14,
    fontWeight: "600",
    color: colors.darkest,
  },
});

export default NtLocationItem;
