import React, { useEffect } from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import routes from "../../navigation/routes";
import { useNavigation } from "@react-navigation/native";
import { useMobile } from "../../hooks/useMobile";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtLogo from "../../components/NtLogo";

function NtFooterBar(probs) {
  const nav = useNavigation();
  const isMobile = useMobile();

  const legal = [
    {
      name: "Terms of Service",
      action: () => {
        nav.navigate(routes.terms);
      },
    },
    {
      name: "Privacy Policy",
      action: () => {
        nav.navigate(routes.privacy);
      },
    },
    {
      name: "Cookies Policy",
      action: () => {
        nav.navigate(routes.cookies);
      },
    },
  ];

  const contact = [
    {
      name: "Contact us",
      action: () => {},
    },
  ];

  const social = [
    {
      renderIcon: (
        <NtMaterialIcon size={20} color={"white"} name={"facebook"} />
      ),
      name: "Facebook",
      action: () => {
        window.open("https://www.facebook.com/noozinsiders");
      },
    },
    {
      renderIcon: (
        <NtMaterialIcon size={20} color={"white"} name={"linkedin"} />
      ),
      name: "LinkedIn",
      action: () => {
        window.open("https://www.linkedin.com/company/nooz-ai");
      },
    },
    {
      renderIcon: <NtMaterialIcon size={20} color={"white"} name={"twitter"} />,
      name: "Twitter",
      action: () => {
        window.open("https://twitter.com/nooz_ai");
      },
    },
    {
      renderIcon: (
        <NtMaterialIcon size={20} color={"white"} name={"instagram"} />
      ),
      name: "Instagram",
      action: () => {
        window.open("https://www.instagram.com/nooz_ai/");
      },
    },
    {
      renderIcon: <NtMaterialIcon size={20} color={"white"} name={"youtube"} />,
      name: "Youtube",
      action: () => {
        window.open("https://www.youtube.com/channel/UC2HNsfrHZlvan-SoXj6ckVQ");
      },
    },
    {
      renderIcon: (
        <Image
          style={{ width: 20, height: 20 }}
          source={require("../../assets/images/tiktok-200x200.png")}
        />
      ),
      name: "TikTok",
      action: () => {
        window.open("https://www.tiktok.com/@nooz_ai");
      },
    },
  ];

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: isMobile ? "column-reverse" : "row" }}>
        {/* logo container */}
        <View style={[styles.logoContainer, { flex: isMobile ? -1 : 1 }]}>
          <NtLogo
            onPress={() => {
              nav.navigate(routes.dashboard);
            }}
          />
        </View>

        {/* legal container */}
        <View style={[styles.legalContainer, { flex: isMobile ? -1 : 1 }]}>
          <NtText style={[styles.sectionTitle, { marginBottom: 10 }]}>
            Legal
          </NtText>

          {legal.map((each) => (
            <View key={each.name} style={{ marginBottom: 10 }}>
              <NtTouchableEffect onPress={each.action}>
                <NtText style={styles.textLink}>{each.name}</NtText>
              </NtTouchableEffect>
            </View>
          ))}

          <NtText
            style={[styles.sectionTitle, { marginTop: 15, marginBottom: 10 }]}
          >
            Contact
          </NtText>

          {contact.map((each) => (
            <View key={each.name} style={{ marginBottom: 10 }}>
              <NtTouchableEffect onPress={each.action}>
                <NtText style={styles.textLink}>{each.name}</NtText>
              </NtTouchableEffect>
            </View>
          ))}
        </View>

        {/* social container */}
        <View style={[styles.socialContainer, { flex: isMobile ? -1 : 1 }]}>
          <NtText style={[styles.sectionTitle, { marginBottom: 10 }]}>
            Social
          </NtText>
          {social.map((each) => (
            <View key={each.name} style={{ marginBottom: 10 }}>
              <NtTouchableEffect
                style={{ flexDirection: "row", alignItems: "center" }}
                onPress={each.action}
              >
                {each.renderIcon}
                <NtText style={[styles.textLink, { marginLeft: 5 }]}>
                  {each.name}
                </NtText>
              </NtTouchableEffect>
            </View>
          ))}
        </View>
      </View>

      <NtText style={styles.copyright}>
        Copyright © 2022 - {new Date().getFullYear()} NTCONNECT All Rights
        Reserved.
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#0957A2",
    padding: 30,
  },
  logoContainer: {
    padding: 10,
    alignItems: "center",
  },
  legalContainer: {
    padding: 10,
  },
  socialContainer: {
    padding: 10,
  },
  appContainer: {
    padding: 10,
  },
  sectionTitle: {
    color: "white",
    fontSize: 15,
    fontWeight: "600",
  },
  appLink: {
    color: "white",
    fontWeight: "600",
    fontSize: 14,
  },

  textLink: {
    color: colors.lighter,
  },
  image: {
    width: 180,
    height: 60,
    resizeMode: "contain",
  },
  copyright: {
    color: "white",
    width: "100%",
    textAlign: "center",
    marginTop: 25,
  },
});

export default NtFooterBar;
