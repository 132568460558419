import React from "react";
import { View, StyleSheet, Text, FlatList } from "react-native";
import colors from "../config/colors";
import NtSelectionSheetrow from "./NtSelectionSheetRow";
import NtText from "./NtText";
import Logger from "../common/utils/Logger";

function NtSelectionSheet({
  title,
  options,
  selected,
  onSelection,
  selectedOn,
  displayOn,
}) {
  let name = "id";
  let display = "title";

  if (selectedOn) {
    name = selectedOn;
  }

  if (displayOn) {
    display = displayOn;
  }

  const renderSeparator = () => (
    <View style={{ height: 0.5, backgroundColor: colors.teal }} />
  );

  return (
    <View style={styles.container}>
      <NtText style={[styles.title, { marginBottom: 20 }]}>{title}</NtText>
      {renderSeparator()}
      <FlatList
        data={options}
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => renderSeparator()}
        keyExtractor={(item) => item[name].toString()}
        renderItem={({ item }) => (
          <NtSelectionSheetrow
            title={item[display]}
            icon={item.icon}
            renderIcon={item.renderIcon}
            selected={selected ? selected === item[name] : false}
            onPress={() => {
              onSelection(item);
            }}
          />
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    width: "100%",
    fontSize: 18,
    color: colors.darkest,
    fontWeight: "600",
    textAlign: "center",
  },
});

export default NtSelectionSheet;
