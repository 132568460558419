import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";

function NtLinkItem({
  item,
  iconColor = colors.darkest,
  titleColor = colors.darkest,
  subtitleColor = colors.darkest,
  disclosureColor = colors.darkest,
  containerStyle,
  onPress,
  showDisclosure = true,
}) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      <NtMaterialIcon name={item.icon} size={20} color={iconColor} />
      <View style={{ marginLeft: 10, flex: 1 }}>
        <View style={{ flexDirection: "row" }}>
          <NtText style={[styles.title, { color: titleColor }]}>
            {" "}
            {item.title}
          </NtText>
        </View>

        {item.subtitle && (
          <NtText
            style={[styles.subTitle, { marginTop: 6, color: subtitleColor }]}
          >
            {item.subtitle}
          </NtText>
        )}
      </View>

      {item.renderView && item.renderView}
      {!item.renderView && showDisclosure && (
        <NtMaterialIcon
          size={22}
          name="chevron-right"
          color={disclosureColor}
        />
      )}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lighestGray,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 11,
    paddingRight: 11,
    borderRadius: 6,
    flexDirection: "row",
    alignItems: "center",
    maxWidth: 200,
  },
});

export default NtLinkItem;
