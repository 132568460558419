import React, { useContext, useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtNavigationButton from "./NtNavigationButton";
import UserContext from "../../context/UserContext";
import { useMobile } from "../../hooks/useMobile";
import { useNavigation } from "@react-navigation/native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import routes from "../../navigation/routes";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtLogo from "../../components/NtLogo";
import NtLocationItem from "./NtLocationItem";

function NavigationBar({ navItems, currentController, onNavPress }) {
  const context = useContext(UserContext);
  const nav = useNavigation();
  const isMobile = useMobile();

  const renderNavItem = (item) => {
    return (
      <View key={item.name} style={{ flexDirection: "row", marginRight: 30 }}>
        <NtNavigationButton
          isMobile={isMobile}
          title={item.name}
          icon={item.icon}
          selected={currentController === item.route}
          onPress={() => {
            nav.navigate(item.route);
          }}
        />
      </View>
    );
  };

  const renderLocatioItem = () => {
    return <NtLocationItem containerStyle={{ marginRight: 20 }} />;
  };

  const renderLogout = () => {
    if (!context.user.authenticated) {
      return null;
    }

    return (
      <NtTouchableEffect
        onPress={() => {
          context.updateUser({});
        }}
        style={{ flexDirection: "row", alignItems: "center" }}
      >
        <NtMaterialIcon name="logout" color="white" />
        <NtText style={[styles.logout, { marginLeft: 6 }]}>Logout</NtText>
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtLogo
        onPress={() => {
          nav.navigate(routes.dashboard);
        }}
      />

      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {navItems && navItems.map((each) => renderNavItem(each))}
        {renderLocatioItem()}
        {renderLogout()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#0957A2",
    elevation: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 20,
  },
  image: {
    height: 40,
    width: 150,
    resizeMode: "contain",
  },
  logout: {
    color: "white",
    fontSize: 14,
    fontWeight: "600",
  },
});

export default NavigationBar;
