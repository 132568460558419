import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../base/BaseController";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtText from "../../components/NtText";
import NtLinkHeaderItem from "../components/NtLinkHeaderItem";
import NtLinkItem from "../components/NtLinkItem";

class LinkController extends BaseController {
  state = {};

  options = [
    {
      title: "oneConnect",
      items: [
        {
          id: "oneconnect",
          icon: "ship-wheel",
          title: "One Connect",
          url: `https://oneconnect.${this.context.user?.location?.domain}/core/dashboard`,
        },
      ],
    },
    {
      title: "Rancher",
      items: [
        {
          id: "rancher",
          icon: "cow",
          title: "Rancher",
          url: `https://rancher.${this.context.user?.location?.domain}:8443/dashboard`,
        },
      ],
    },
  ];

  renderRow = (item) => {
    return (
      <NtLinkItem
        key={item.id}
        item={item}
        onPress={() => {
          window.open(item.url);
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Links"}
        showFooter={true}
        showNavigation={false}
        showBackButton={true}
      >
        {this.options.map((each, index) => (
          <View
            key={each.title + index.toString() + JSON.stringify(this.props)}
          >
            <NtLinkHeaderItem
              title={each.title}
              containerStyle={{
                marginLeft: 10,
                marginTop: 20,
                marginBottom: 10,
              }}
            />
            {each.items.map((item) => this.renderRow(item))}
          </View>
        ))}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default LinkController;
