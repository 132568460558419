import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtDevider from "../../components/NtDevider";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtExtensionReportItem({ item, onGatewayPress }) {
  const renderDesctipion = () => {
    if (item?.description?.match(/\d+,\d+,\d+,\d+/)) {
      return (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtTouchableEffect
            onPress={() => {
              if (onGatewayPress) {
                onGatewayPress(item?.description?.split(",")[0]);
              }
            }}
          >
            <NtText style={styles.gateway}>
              Gateway = {item?.description?.split(",")[0]}
            </NtText>
          </NtTouchableEffect>

          <NtText style={[styles.gateway, { marginLeft: 10 }]}>
            Port = {item?.description?.split(",")[1]}
          </NtText>
        </View>
      );
    }
    return <NtText style={styles.description}>{item.description}</NtText>;
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", padding: 6, alignItems: "center" }}>
        <View style={{ flex: 0.5 }}>
          <NtText style={styles.extension}>
            {item.extension?.substring(4)}
          </NtText>
        </View>

        <View style={{ flex: 1 }}>
          <NtText style={styles.name}>{item.effective_caller_id_name}</NtText>
        </View>

        {/* description... */}
        <View style={{ flex: 1 }}>{renderDesctipion()}</View>
      </View>
      <NtDevider />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  extension: {
    fontSize: 15,
    fontWeight: "600",
    color: colors.darkest,
  },
  name: {
    fontSize: 15,
    fontWeight: "500",
    color: colors.lighGray,
  },
  description: {
    fontSize: 15,
    fontWeight: "500",
    color: colors.lighGray,
  },
  gateway: {
    fontSize: 13,
    fontWeight: "600",
    backgroundColor: colors.nettalkBlue,
    color: "white",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 30,
  },
});

export default NtExtensionReportItem;
