import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import {
  handleAppUPloadFailure,
  handleAppUploadCreate,
} from "../utils/AppAccessUploadParser";
import Logger from "../../common/utils/Logger";
import UserContext from "../../context/UserContext";

function NtAppAccessUploadForm({ onCancel, onUploaded }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);

  const context = useContext(UserContext);

  const handleUpload = async () => {
    setLoading(true);
    const response = await handleAppUploadCreate(file, context.user?.location);
    setLoading(false);
    setUploadResponse(response);
  };

  const handleDone = () => {
    if (onUploaded) {
      onUploaded(uploadResponse);
    }
  };

  const handleFailureDownload = async () => {
    const response = await handleAppUPloadFailure(uploadResponse.failure);
    const fileData = response.data;

    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "failure-uploaded-appaccess.txt";
    link.href = url;
    link.click();
  };

  const isRequiredFields = () => {
    return file;
  };

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
  };

  const renderChooseCSV = () => {
    if (file) {
      return null;
    }
    return (
      <NtTouchableEffect
        style={{}}
        onPress={() => {
          document.getElementById("selectFile").click();
        }}
      >
        <NtText
          style={{ fontSize: 13, fontWeight: "400", color: colors.nettalkBlue }}
        >
          Choose CSV file
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderInfoCSV = () => {
    if (!file) {
      return null;
    }
    return (
      <View>
        <NtText>{file?.name}</NtText>
      </View>
    );
  };

  const renderCancel = () => {
    if (uploadResponse) {
      return null;
    }
    return (
      <NtTouchableEffect style={styles.cancelButton} onPress={onCancel}>
        <NtText
          style={{ fontSize: 13, fontWeight: "600", color: colors.darkest }}
        >
          Cancel
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderUpload = (containerStyle) => {
    return (
      <NtTouchableEffect
        style={[
          styles.addButton,
          containerStyle,
          {
            backgroundColor: isRequiredFields()
              ? colors.nettalkBlue
              : colors.lighGray,
          },
        ]}
        onPress={
          isRequiredFields()
            ? () => {
                if (uploadResponse) {
                  handleDone();
                } else {
                  handleUpload();
                }
              }
            : null
        }
      >
        <NtText style={{ fontSize: 13, fontWeight: "600", color: "white" }}>
          {uploadResponse ? "Done" : "Upload"}
        </NtText>
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Upload csv access for app</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        This will allow the app to be able to sign in using PIN. columns MUST be
        named (case sensitive):{" "}
        <NtText style={[styles.subtitle, { fontWeight: "bold" }]}>
          extension
        </NtText>
        ,{" "}
        <NtText style={[styles.subtitle, { fontWeight: "bold" }]}>email</NtText>
      </NtText>

      <View
        style={{
          borderRadius: 4,
          borderWidth: 1,
          borderColor: colors.lightest,
          backgroundColor: colors.lighestGray,
          justifyContent: "center",
          padding: 10,
          marginTop: 30,
        }}
      >
        {renderChooseCSV()}
        {renderInfoCSV()}
      </View>
      {uploadResponse && (
        <View
          style={{
            marginTop: 6,
          }}
        >
          <NtText style={[styles.uploadResponseText]}>
            Total: {uploadResponse?.total}
          </NtText>
          <NtText style={[styles.uploadResponseText, { marginTop: 4 }]}>
            Success: {uploadResponse?.success?.length}
          </NtText>
          <NtTouchableEffect
            style={{ marginTop: 4 }}
            onPress={() => {
              if (uploadResponse?.failure?.length > 0) {
                handleFailureDownload();
              }
            }}
          >
            <NtText style={[styles.uploadResponseText, { color: "red" }]}>
              Error: {uploadResponse?.failure?.length}
            </NtText>
          </NtTouchableEffect>
        </View>
      )}

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.nettalkBlue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}
        {renderCancel()}
        {renderUpload({ marginLeft: 10 })}
      </View>

      <input
        id="selectFile"
        type="file"
        name="file"
        accept=".csv"
        style={{ display: "none" }}
        onChange={changeHandler}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.lighGray,
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
});

export default NtAppAccessUploadForm;
