import React, { useState, useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtLoginHALoginButtonTypePicker from "./NtLoginHALoginButtonTypePicker";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtModal from "../../components/NtModal";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";

function NtLoginHALoginButton({
  containerStyle,
  loading = false,
  onPress,
  onOptionPress,
  options,
  selected,
}) {
  const typesPickerRef = useRef(null);

  const renderTypesPickerModal = () => {
    return (
      <NtModal
        ref={typesPickerRef}
        renderBody={
          <NtLoginHALoginButtonTypePicker
            types={options}
            selected={selected}
            onPress={(selected) => {
              typesPickerRef?.current.dismiss();
              if (onOptionPress) {
                onOptionPress(selected);
              }
            }}
          />
        }
      />
    );
  };

  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      <View
        style={[
          styles.login,
          { backgroundColor: onPress ? colors.nettalkBlue : "#f2c066" },
        ]}
      >
        <NtText style={{ color: "white", fontSize: 14, fontWeight: "600" }}>
          Login using {selected?.title}
        </NtText>
      </View>

      <NtTouchableEffect
        style={[
          styles.type,
          {
            marginLeft: 2,
            backgroundColor: onPress ? colors.nettalkBlue : "#f2c066",
          },
        ]}
        onPress={() => {
          typesPickerRef?.current.show();
        }}
      >
        {!loading && <NtMaterialIcon name={selected?.icon} color="white" />}
        {loading && <NtActivityIndicator size="small" color="white" />}
      </NtTouchableEffect>

      {renderTypesPickerModal()}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  login: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#f2c066",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    padding: 8,
  },
  type: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#f2c066",
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    padding: 8,
  },
});

export default NtLoginHALoginButton;
