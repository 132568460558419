import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "./NtText";
import NtTouchableEffect from "./NtTouchableEffect";

function NtLogo({ size = "small", color = "white", onPress }) {
  const getSize = () => {
    if (size == "small") {
      return 20;
    } else if (size == "big") {
      return 40;
    }
  };

  return (
    <NtTouchableEffect style={styles.container} onPress={onPress}>
      <NtText style={[styles.title, { color: color, fontSize: getSize() }]}>
        Logo
      </NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});

export default NtLogo;
