import Papa from "papaparse";
import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";
import exeptionblocks from "../data/exeptionblocks";
import md5 from "react-native-md5";

export const handleRateCreate = async (file, provider, tariff) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        Logger("Done reading the .csv... will start processing now..");
        const rates = [];

        results.data.map((each) => {
          let buyInter = each.inter;
          let buyIntra = each.intra;

          let rateInter = (settings.rateMultiplier * each.inter).toFixed(7);
          let rateIntra = (settings.rateMultiplier * each.intra).toFixed(7);

          //will figure out if needs to $1000
          if (each.intra > settings.rateThreshold) {
            buyInter = buyIntra = rateInter = rateIntra = 1000;
          }

          //will figure out what blocks to use.....

          const block = getBlock(each.dialprefix, tariff);

          rates.push({
            idtariffplan: tariff.id,
            dialprefix: `${provider.append}${each.dialprefix}`,
            buyrate: buyInter,
            buyrateinter: buyInter,
            buyrateintra: buyIntra,
            buyrateinitblock: block.initial,
            buyrateincrement: block.increment,
            rateinitial: rateInter,
            rateinter: rateInter,
            rateintra: rateIntra,
            initblock: "60",
            billingblock: "60",
            startdate: "now()",
            stopdate: "2038-01-01 00:00:00",
            id_trunk: provider.id,
            tag: "APP",
            destination: each.dialprefix,
          });
        });

        resolve({
          ok: true,
          data: rates,
        });
      },
    });
  });
};

export const getBlock = (dialprefix, tariff) => {
  if (tariff.id === 1121) {
    //domestic
    return { initial: "6", increment: "6" };
  }

  //will try to see if we are in the exemption block
  const found = exeptionblocks.find((each) =>
    dialprefix.startsWith(each.country)
  );

  if (found) {
    Logger("Found Exeption", found);
    return { initial: found.initial, increment: found.increment };
  }

  //if it wasn't domestic and not in the exeption.... it will be 1/1
  return { initial: "1", increment: "1" };
};

export const handleRateUpdateSQL = async (file, rates, provider) => {
  return new Promise((resolve, reject) => {
    const tmpTable = `cc_ratecard_2_tmp_${md5.hex_md5(file.name)}`;
    const rateTable = "cc_ratecard_2";

    let data = "-- Will create the tmp table to save the current rates";
    data += `\nCREATE TABLE IF NOT EXISTS ${tmpTable} LIKE ${rateTable};`;

    //will copy the current rates to the tmp table....
    data += "\n\n-- Will move the current rates into the tmp table";
    data += `\nINSERT INTO ${tmpTable} SELECT * FROM ${rateTable} WHERE id_trunk = ${provider.id};`;

    data += "\n\n-- Will delete the current rates to insert the new ones";
    data += `\nDELETE FROM ${rateTable} WHERE id_trunk = ${provider.id};`;

    data += "\n\n -- Will insert the new rates";
    data += getInsertHeaders(rateTable);

    rates.map((each) => {
      data += `\n(${each.idtariffplan}, ${each.dialprefix}, ${each.buyrate}, ${each.buyrateinter}, ${each.buyrateintra}, ${each.buyrateinitblock}, ${each.buyrateincrement}, ${each.rateinitial}, ${each.rateinter}, ${each.rateintra}, ${each.initblock}, ${each.billingblock}, ${each.startdate}, '${each.stopdate}', ${each.id_trunk}, '${each.tag}', ${each.destination}),`;
    });

    // will remove the last ,
    data = data.substring(0, data.length - 1);
    data += ";";

    resolve({
      ok: true,
      data: data,
    });
  });
};

export const handleRateRollbackSQL = async (file, provider) => {
  return new Promise((resolve, reject) => {
    const tmpTable = `cc_ratecard_2_tmp_${md5.hex_md5(file.name)}`;
    const rateTable = "cc_ratecard_2";

    let data = "-- Will delete the current rates to insert the old ones";
    data += `\nDELETE FROM ${rateTable} WHERE id_trunk = ${provider.id};`;

    //will copy the current rates to the tmp table....
    data += `\n\n-- Will move the current rates from tmp table to rates table`;
    data += `\nINSERT INTO ${rateTable} SELECT * FROM ${tmpTable} WHERE id_trunk = ${provider.id};`;

    resolve({
      ok: true,
      data: data,
    });
  });
};

export const getInsertHeaders = (table) => {
  return `\nINSERT INTO ${table} (idtariffplan, dialprefix, buyrate, buyrateinter, buyrateintra, buyrateinitblock, buyrateincrement, rateinitial, rateinter, rateintra, initblock, billingblock, startdate, stopdate, id_trunk, tag, destination) VALUES `;
};
