import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtBorderTextView from "../../components/NtBorderTextView";
import NtActivityIndicator from "../../components/NtActivityIndicator";

function NtFileButton({
  containerStyle,
  onPress,
  loading = false,
  title,
  icon,
}) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      {!loading && <NtMaterialIcon name={icon} color={colors.lighGray} />}
      {loading && <NtActivityIndicator size="small" color={colors.lighGray} />}
      <NtText style={[styles.title, { marginLeft: 6 }]}>{title}</NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    borderRadius: 6,
    borderColor: colors.lighGray,
    borderWidth: 1,
  },
  title: {
    color: colors.lighGray,
  },
});

export default NtFileButton;
