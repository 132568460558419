import React from "react";
import { View, StyleSheet, Image, ScrollView } from "react-native";
import colors from "../../config/colors";
import NtTextInput from "../../components/NtTextInput";
import {
  authenticate,
  authenticateRequestCode,
  authenticateVerifyCode,
} from "../../api/auth";
import { showToast } from "../../common/utils/ControllerUtils";
import BaseController from "../../common/base/BaseController";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtFormButtonNext from "../../common/components/NtFormButtonNext";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtInputCode from "../components/NtInputCode";
import Logger from "../../common/utils/Logger";
import { fetchExtensionByNumber } from "../../api/extension";

class AuthCodeController extends BaseController {
  state = {
    code: "",
    loading: false,
    requestingCode: false,
  };

  componentDidMount() {
    document.title = "NOOZ.AI | Log In";
    Logger("props", this.props, this.props.route.params);
  }

  handleAuthCodeVerify = async () => {
    this.setState({ loading: true });

    const response = await authenticateVerifyCode(
      `${this.props.route?.params?.extension}@${this.context.user?.location?.domain}`,
      this.state.code
    );

    if (response.ok) {
      this.handleExtensionFetch();
    } else {
      showToast("Oops...", "Invalid code, Please try again.");
    }

    this.setState({ loading: false });
  };

  handleExtensionFetch = async () => {
    this.setState({ loading: true });

    const response = await fetchExtensionByNumber(
      this.props.route?.params?.extension,
      this.context.user?.location?.domain
    );
    if (response.ok) {
      this.setState({ code: "" });
      this.context.updateUser({
        ...this.context.user,
        ...response.data?.extensions,
        authenticated: true,
      });
    } else {
      showToast("Oops...", "Unable to process request. Please try again.");
    }

    this.setState({ loading: false });
  };

  handleAuthCodeRequest = async () => {
    this.setState({ requestingCode: true });

    const response = await authenticateRequestCode(
      `${this.props.route?.params?.extension}@${this.context.user?.location?.domain}`
    );
    if (response.ok) {
      showToast(
        "Success",
        "Successfully requested a new code. Please check your email."
      );
    } else {
      showToast(
        "Oops...",
        "Unable to request a code. Please try again later.",
        "error"
      );
    }

    this.setState({ requestingCode: false });
  };

  isFieldsRequired = () => {
    return this.state.code && this.state.code.length == 6;
  };

  renderResendLink = (containerStyle) => {
    return (
      <View
        style={[{ flexDirection: "row", alignItems: "center" }, containerStyle]}
      >
        <NtText
          style={{ fontSize: 14, color: colors.lighGray, fontWeight: "600" }}
        >
          Did not get the code?
        </NtText>
        <NtTouchableEffect
          onPress={() => {
            this.handleAuthCodeRequest();
          }}
        >
          <NtText
            style={{
              fontSize: 14,
              color: colors.nettalkBlue,
              fontWeight: "800",
              marginLeft: 10,
            }}
          >
            Resend code
          </NtText>
        </NtTouchableEffect>

        {this.state.requestingCode && (
          <NtActivityIndicator
            containerStyle={{ marginLeft: 10 }}
            size="small"
            color={colors.nettalkBlue}
          />
        )}
      </View>
    );
  };

  render() {
    return (
      <NtRootPageContainer
        showNavigation={false}
        showFooter={true}
        renderTitleView={<View />}
      >
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              maxWidth: 550,
              minWidth: 400,
              backgroundColor: colors.lighestGray,
              padding: 35,
              borderRadius: 10,
            }}
          >
            <NtText style={[styles.title]}>Code Validation</NtText>
            <NtText style={[styles.subtitle, { marginTop: 6 }]}>
              Please enter the code received via email
            </NtText>

            {this.renderResendLink({ marginTop: 25 })}

            <View style={{ marginTop: 15 }}>
              <NtInputCode
                containerStyle={{ marginTop: 20, marginBottom: 10 }}
                cellNotEmptyColor="#373737"
                onTextChange={(text) => {
                  this.setState({ code: text });
                }}
              />
            </View>

            <View
              style={{
                marginTop: 35,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Image
                style={[styles.protectedImage, { marginRight: 15 }]}
                source={require("../../assets/images/nt_protect_logo.png")}
              />
              <NtFormButtonNext
                onPress={
                  this.isFieldsRequired()
                    ? () => {
                        this.handleAuthCodeVerify();
                      }
                    : null
                }
                title="Validate Code"
                containerStyle={{
                  width: 160,
                  backgroundColor: this.isFieldsRequired()
                    ? colors.nettalkBlue
                    : "#f2c066",
                }}
                loading={this.state.loading}
              />
            </View>
          </View>
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  input: {
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%",
    height: 45,
    backgroundColor: "white",
    borderRadius: 12,
  },
  title: {
    fontSize: 28,
    fontWeight: "bold",
    color: colors.darkest,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "#f2c066",
  },
  info: {
    textAlign: "center",
    color: colors.darker,
  },
  protectedImage: {
    height: 40,
    width: 40,
    resizeMode: "contain",
  },
});

export default AuthCodeController;
