import React from "react";
import { View, StyleSheet } from "react-native";
import BaseExtensionController from "./BaseExtensionController";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import Logger from "../../common/utils/Logger";
import client from "../../api/client";
import locationsdata from "../../root/data/locationsdata";
import { fetchExtensions } from "../../api/extension";
import { sleep } from "../../common/utils/ControllerUtils";
import NtText from "../../components/NtText";
import NtGatewayReportColumnItem from "../components/NtGatewayReportColumnItem";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import colors from "../../config/colors";
import NtGatewayCSVButton from "../components/NtGatewayCSVButton";

class GatewayReportController extends BaseExtensionController {
  state = {
    data: [],
    maxGateway: 0,
    loading: false,
    currentSite: null,
  };

  sites = [
    {
      code: "CPL",
    },
    {
      code: "KPL",
    },
    {
      code: "DPL",
    },
    {
      code: "FPH",
    },
    {
      code: "FAW",
    },
    {
      code: "MPL",
    },
    {
      code: "CHT",
    },
    {
      code: "SGW",
    },
  ];

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
    Logger("I am unmounting, will set the API back to normal");
    client.setBaseURL(
      `http://${this.context.user?.location?.domain}:31184/api/v1`
    );
  }

  handleFocus = () => {
    Logger("On focus change");
  };

  handleDataFetch = async () => {
    this.setState({ loading: true });
    let allItems = [];
    for (let x = 0; x < this.sites.length; x++) {
      const location = locationsdata.find(
        (each) => each.code == this.sites[x].code
      );

      //will get the extensions for each site.....
      client.setBaseURL(`http://${location?.domain}:31184/api/v1`);
      // await sleep(1000);

      Logger("Fetching for domain ", location.code);
      this.setState({ currentSite: location });

      const response = await fetchExtensions(location.domain, 1, 10000, "9999");
      if (response.ok) {
        let data = this.filterExtensions(response.data?.extensions, "room");
        //will sort by gateway
        data = data.sort(
          (a, b) => a.description.split(",")[0] - b.description.split(",")[0]
        );

        if (response.ok) {
          allItems.push({
            code: location?.code,
            data: data,
          });
        }
      }
    }

    // Will try to find the max number of gateways.....
    let maxGateway = 1;
    allItems.map((each) => {
      if (
        parseInt(each.data[each.data.length - 1]?.description?.split(",")[0]) >
        maxGateway
      ) {
        maxGateway = parseInt(
          each.data[each.data.length - 1]?.description?.split(",")[0]
        );
      }
    });

    Logger("Fetched data done", allItems, maxGateway);
    this.setState({
      data: allItems,
      maxGateway: maxGateway,
      loading: false,
      currentSite: null,
    });
  };

  filterExtensions = (list, type) => {
    if (type == "room") {
      return list.filter(
        (each) =>
          each.description?.match(/\d+,\d+,\d+,\d+/) &&
          // each.extension < 999990000 &&
          each.description?.split(",")[0] > 0
      );
    }
  };

  renderRow = (item, index) => {
    const data = this.state.data.find((each) => each.code == item.code);

    return (
      <NtGatewayReportColumnItem
        containerStyle={{ marginRight: 2 }}
        key={index}
        site={item}
        loading={item.code == this.state.currentSite?.code}
        data={data?.data}
        maxGateway={
          data ? data.data[data.data.length - 1]?.description?.split(",")[0] : 0
        }
        total={this.state.maxGateway}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Gateway Report"}
        showFooter={true}
        showNavigation={false}
        showBackButton={true}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <View>
            {!this.state.loading && (
              <View style={{ alignItems: "flex-start", marginBottom: 20 }}>
                <NtGatewayCSVButton
                  data={this.state.data}
                  maxGateway={this.state.maxGateway}
                />
              </View>
            )}
            <View
              style={{
                backgroundColor: colors.lighestGray,
                padding: 15,
                flexDirection: "row",
                borderRadius: 10,
              }}
            >
              {this.renderRow({ code: "Gateway" }, 0)}
              {this.sites.map((each, index) => this.renderRow(each, index))}
            </View>
          </View>
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default GatewayReportController;
