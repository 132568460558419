const exeptionblocks = [
  //exeptions.... the rest is 6/6
  {
    name: "Mexico",
    country: 52,
    initial: 60,
    increment: 60,
  },
  {
    name: "Haiti",
    country: 509,
    initial: 60,
    increment: 60,
  },
  {
    name: "Suriname Mobile Digicel",
    country: 597,
    initial: 60,
    increment: 60,
  },
  {
    name: "Suriname Proper and Mobile Telesur/Mobile Other",
    country: -1,
    initial: 1,
    increment: 1,
  },
  {
    name: "Papua New Guinea",
    country: 675,
    initial: 60,
    increment: 60,
  },
  {
    name: "Tonga Islands",
    country: 676,
    initial: 60,
    increment: 60,
  },
  {
    name: "Vanuatu",
    country: 678,
    initial: 60,
    increment: 60,
  },
  {
    name: "Western Samoa",
    country: 685,
    initial: 60,
    increment: 60,
  },
  {
    name: "Samoa",
    country: 685,
    initial: 60,
    increment: 60,
  },
  {
    name: "Niue",
    country: 683,
    initial: 60,
    increment: 60,
  },
  {
    name: "Nauru",
    country: 674,
    initial: 60,
    increment: 60,
  },
  {
    name: "New Caledonia",
    country: 687,
    initial: 60,
    increment: 60,
  },
  {
    name: "Lesotho",
    country: 266,
    initial: 60,
    increment: 60,
  },
  {
    name: "Cook Islands",
    country: 682,
    initial: 60,
    increment: 60,
  },
  {
    name: "Tokelau",
    country: 690,
    initial: 60,
    increment: 60,
  },
  {
    name: "Kiribati",
    country: 686,
    initial: 60,
    increment: 60,
  },
  {
    name: "French Polynesia",
    country: 689,
    initial: 60,
    increment: 60,
  },
  {
    name: "Maldives",
    country: 960,
    initial: 60,
    increment: 60,
  },
  {
    name: "Vietnam",
    country: 84,
    initial: 60,
    increment: 1,
  },
  {
    name: "New Zealand",
    country: 64,
    initial: 60,
    increment: 1,
  },
];

export default exeptionblocks;
