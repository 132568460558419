import React, { useState } from "react";
import { View, StyleSheet, TextInput } from "react-native";
import NtTouchableEffect from "./NtTouchableEffect";
import NtMaterialIcon from "./NtMaterialIcon";
import colors from "../config/colors";

function NtTextInput({
  showEye = false,
  containerStyle,
  textStyle,
  ...otherProps
}) {
  const [secureEntry, setSecureEntry] = useState(otherProps?.secureTextEntry);

  return (
    <View style={[styles.container, containerStyle]}>
      <TextInput
        style={[styles.text, textStyle]}
        {...otherProps}
        secureTextEntry={secureEntry}
      />
      {showEye && (
        <NtTouchableEffect
          style={{ position: "absolute", right: 10 }}
          onPress={() => {
            setSecureEntry(!secureEntry);
          }}
        >
          <NtMaterialIcon
            name={!secureEntry ? "eye" : "eye-off"}
            color={colors.dark}
            size={23}
          />
        </NtTouchableEffect>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
  },
  text: {
    outlineColor: colors.lighter,
    outlineStyle: "none",
  },
});

export default NtTextInput;
