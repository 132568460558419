import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";
import { createExtension } from "../../api/extension";
import { sleep } from "../../common/utils/ControllerUtils";

export const handleCSVDownloadData = async (data, maxGateway, showTemp) => {
  return new Promise((resolve, reject) => {
    const handleColumnData = (item, column) => {
      if (column == "gateway") {
        if (item.description?.match(/\d+,\d+,\d+,\d+/)) {
          return item.description.split(",")[0];
        }
        return "";
      } else if (column == "port") {
        if (item.description?.match(/\d+,\d+,\d+,\d+/)) {
          return item.description.split(",")[1];
        }
        return "";
      } else if (column == "extension") {
        return item[column]?.substring(4);
      }
      return item[column];
    };

    const condition = (item) => {
      if (showTemp) {
        return true;
      } else {
        return item.extension < 999990000;
      }
    };

    const filterDataForGateway = (data, gateway) => {
      return data.filter(
        (each) => each.description?.split(",")[0] == gateway && condition(each)
      );
    };

    let csvData = "Gateway,";

    //first... will put all the column names...
    data.map((each) => {
      csvData += `${each.code},`;
    });
    csvData = csvData.substring(0, csvData.length - 1);

    //will now populate the data....
    Array.from(Array(parseInt(maxGateway))).map((each, index) => {
      let row = `${index + 1},`;
      data.map((each) => {
        const siteMaxGateway = each.data
          ? each.data[each.data.length - 1]?.description?.split(",")[0]
          : 0;

        if (index + 1 > parseInt(siteMaxGateway)) {
          row += "-,";
        } else {
          row += `${filterDataForGateway(each.data, index + 1)?.length},`;
        }
      });
      row = row.substring(0, row.length - 1);
      csvData += `\n${row}`;
    });

    Logger("------------ csvData", csvData);

    resolve({ ok: true, data: csvData });
  });
};
