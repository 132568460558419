import Logger from "../../common/utils/Logger";
import Toast from "react-native-toast-message";
import { Share, Alert } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import colors from "../../config/colors";

export const showToast = (title, message, type = "success") => {
  Toast.show({
    type: type,
    text1: title,
    text2: message,
  });
};

export const generateKey = (id) => {
  return `${id.toString()}-${Date.now()}`;
  // return `${id.toString()}`;
};

export const showOkDialog = (title, message, onPress) => {
  Alert.alert(title, message, [
    {
      text: "Ok",
      onPress: () => {
        if (onPress) {
          onPress();
        }
      },
    },
  ]);
};

export const sleep = (time) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ ok: true });
    }, time);
  });
};

export const asyncStorageSetItem = async (key, value) => {
  try {
    return await AsyncStorage.setItem(key, value);
  } catch (e) {
    Logger("Unable to save the value to async storage", e);
  }
};

export const asyncStorageGetItem = async (key) => {
  try {
    return await AsyncStorage.getItem(key);
  } catch (e) {
    Logger("Unable to retrieve the item from async storage", e);
  }
};

export const asyncStorageSetData = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    return await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    Logger("Unable to save the value to async storage", e);
  }
};

export const asyncStorageGetData = async (key) => {
  try {
    return await AsyncStorage.getItem(key);
  } catch (e) {
    Logger("Unable to retrieve the stored data", e);
  }
};

export const validateEmail = (email) => {
  console.log(email);
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  return reg.test(email) === true;
};

export const objectivityStyle = (objectivity) => {
  let label = "Extremely\nOpinionated";
  let color = colors.objectivity76to100;
  let icon = "comment-multiple";

  if (objectivity == 0) {
    label = "Neutral";
    color = colors.objectivity0;
    icon = "comment-remove-outline";
  } else if (objectivity <= 25) {
    label = "Slightly\nOpinionated";
    color = colors.objectivity1to25;
    icon = "comment-outline";
  } else if (objectivity <= 50) {
    label = "Partially\nOpinionated";
    color = colors.objectivity26to50;
    icon = "comment-multiple-outline";
  } else if (objectivity <= 75) {
    label = "Highly\nOpinionated";
    color = colors.objectivity51to75;
    icon = "comment";
  }

  return { label: label, color: color, icon: icon };
};

export const sentimentStyle = (sentiment) => {
  let label = "Very\nPositive";
  let color = colors.sentimentPositiveFilled;
  let icon = "emoticon-happy";

  if (sentiment < -50) {
    label = "Very\nNegative";
    color = colors.sentimentNegativeFilled;
    icon = "emoticon-angry";
  } else if (sentiment < 0) {
    label = "Negative";
    color = colors.sentimentNegativeFilled;
    icon = "emoticon-angry-outline";
  } else if (sentiment === 0) {
    label = "Neutral";
    color = colors.sentiment0;
    icon = "emoticon-neutral-outline";
  } else if (sentiment <= 50) {
    label = "Positive";
    color = colors.sentimentPositiveFilled;
    icon = "emoticon-happy-outline";
  }

  return { label: label, color: color, icon: icon };
};

export const revisionStyle = (revision) => {
  let color = colors.revision51to75;
  let icon = "file-edit";

  if (revision <= 1) {
    color = colors.revision1;
    icon = "file-outline";
  } else if (revision <= 25) {
    color = colors.revision2to25;
    icon = "file-edit-outline";
  } else if (revision <= 50) {
    color = colors.revision26to50;
    icon = "file-edit";
  } else if (revision <= 75) {
    color = colors.revision51to75;
    icon = "comment";
  }

  return { color: color, icon: icon, fontColor: colors.darkest };
};
