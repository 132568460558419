const providers = [
  {
    id: 200,
    name: "Peerless Conversational US",
    append: "1",
  },
  {
    id: 230,
    name: "Peerless Conversational CA",
    append: "1",
  },
  {
    id: 201,
    name: "Peerless International",
    append: "",
  },
  {
    id: 202,
    name: "382 DOM Conversational US CA",
    append: "1",
  },
  {
    id: 203,
    name: "382 DDR Conversational US CA",
    append: "1",
  },
  {
    id: 204,
    name: "382 Conversational AZ",
    append: "",
  },
  {
    id: 228,
    name: "382 Wireless Domestic",
    append: "1",
  },
  {
    id: 232,
    name: "382 8yy",
    append: "1",
  },
  {
    id: 206,
    name: "Bandwidth Conversational US 1",
    append: "1",
  },
  {
    id: 207,
    name: "Bandwidth Conversational US 2",
    append: "1",
  },
  {
    id: 211,
    name: "SC Invest 8YY",
    append: "",
  },
  {
    id: 216,
    name: "Lanck AZ",
    append: "",
  },
  //   {
  //     id: 208,
  //     name: "Bandwidth 911",
  //     append: "",
  //   },
  //   {
  //     id: 209,
  //     name: "Marchex 411",
  //     append: "",
  //   },
  //   {
  //     id: 212,
  //     name: "West 8YY",
  //     append: "",
  //   },
  //   {
  //     id: 213,
  //     name: "Peerless 8yy",
  //     append: "",
  //   },
  //   {
  //     id: 218,
  //     name: "Bandwidth 911 Atlanta",
  //     append: "",
  //   },
  //   {
  //     id: 220,
  //     name: "Bandwidth 911 Dallas",
  //     append: "",
  //   },
  //   {
  //     id: 222,
  //     name: "Primus Canada",
  //     append: "",
  //   },
  //   {
  //     id: 224,
  //     name: "Fibernetics Canada Flat",
  //     append: "",
  //   },
];

export default providers;
