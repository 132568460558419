const locationsdata = [
  {
    name: "Development",
    code: "QA",
    domain: "test.pbx.nettalk.com",
    ip_external: "199.193.189.168",
    octet: 9,
  },
  {
    name: "Copper River Princess Lodge",
    code: "CPL",
    domain: "cpl.hap.ntlk.co",
    ip_external: "209.71.59.140",
    octet: 11,
  },
  {
    name: "Kenai Princess Lodge",
    code: "KPL",
    domain: "kpl.hap.ntlk.co",
    ip_external: "63.140.110.251",
    octet: 12,
  },
  {
    name: "Denali Princess Lodge",
    code: "DPL",
    domain: "dpl.hap.ntlk.co",
    ip_external: "69.162.211.28",
    octet: 13,
  },
  {
    name: "Fairbanks Princess Lodge",
    code: "FPH",
    domain: "fph.hap.ntlk.co",
    ip_external: "69.161.25.108",
    octet: 14,
  },
  {
    name: "Westmark Fairbanks Hotel",
    code: "FAW",
    domain: "faw.hap.ntlk.co",
    ip_external: "69.162.211.132",
    octet: 15,
  },
  {
    name: "McKinley Princess Lodge",
    code: "MPL",
    domain: "mpl.hap.ntlk.co",
    ip_external: "69.162.211.156",
    octet: 16,
  },
  {
    name: "McKinley Chalet",
    code: "CHT",
    domain: "cht.hap.ntlk.co",
    ip_external: "209.112.151.76",
    octet: 17,
  },
  {
    name: "Westmark Inn Skagway",
    code: "SGW",
    domain: "sgw.hap.ntlk.co",
    ip_external: "63.140.110.246",
    octet: 18,
  },
  {
    name: "Westmark Dawson",
    code: "YDW",
    domain: "ydw.hap.ntlk.co",
    ip_external: "63.140.110.248",
    octet: 19,
  },
  {
    name: "EWC",
    code: "EWC",
    domain: "ewc.hap.ntlk.co",
    ip_external: "63.140.110.249",
    octet: 1,
  },
];

export default locationsdata;
