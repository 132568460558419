import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtSelectButton({ option, containerStyle, onPress }) {
  return (
    <NtTouchableEffect
      onPress={onPress}
      style={[styles.container, containerStyle]}
    >
      <NtText style={styles.title}>{option.name}</NtText>
      <NtMaterialIcon name="chevron-down" color={colors.lighGray} />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    backgroundColor: "white",
    alignItems: "center",
    borderRadius: 6,
    padding: 10,
  },
  title: {
    color: colors.lighGray,
    fontSize: 15,
    flex: 1,
    fontWeight: "600",
  },
});

export default NtSelectButton;
