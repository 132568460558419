import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";
import Logger from "../../common/utils/Logger";
import { fetchExtensions } from "../../api/extension";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtText from "../../components/NtText";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import settings from "../../config/settings";
import NtExtensionReportItem from "../components/NtExtensionReportItem";
import NtGatwayExtensionItem from "../components/NtGatewayExtensionItem";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";

class GatewayExtensionController extends BaseController {
  state = {
    data: [],
    master: [],
    repeated: [],
    highlightDupes: [],
    loading: false,
  };

  skip = 0;
  pageSize = 30;
  infiniteScrollEnabled = false;

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleExtensionsFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("----- on focus change");
  };

  handleExtensionsFetch = async () => {
    Logger("Fetching the data for Extensions");

    if (this.skip == 0) {
      this.setState({ loading: true });

      const response = await fetchExtensions(
        this.context.user?.location?.domain,
        1,
        10000,
        settings.physicalExtensionPrefix
      );

      if (response.ok) {
        Logger(
          "Loading for the first time, the total items",
          response.data?.extensions?.length
        );

        //will filter base on the tab....
        let master = this.handleGatewayFilter(response.data.extensions);

        // will sort asc...
        master = master.sort(
          (a, b) => a.description.split(",")[1] - b.description.split(",")[1]
        );

        this.handleRepeatedLookup(master);

        this.setState({
          master: master,
          data: master.slice(this.skip, this.pageSize),
        });

        this.infiniteScrollEnabled = true;
      }
    } else {
      Logger("Loading more");
      this.setState({
        data: this.state.data.concat(
          this.state.master.slice(this.skip, this.skip + this.pageSize)
        ),
      });
    }

    this.setState({ loading: false });
  };

  handleGatewayFilter = (list) => {
    return list.filter(
      (each) =>
        each.description?.match(/\d+,\d+,\d+,\d+/) &&
        each.description.split(",")[0] == this.props.route?.params?.gateway
    );
  };

  handleRepeatedLookup = (list) => {
    if (this.props.route?.params?.gateway == 0) {
      return list;
    }

    let highlight = [];
    list.map((each) => {
      const eachPort = each?.description?.split(",")[1];

      const dupes = list.filter(
        (all) => eachPort == all.description?.split(",")[1]
      );
      if (
        dupes?.length > 1 &&
        !highlight.find((each) => each.port == eachPort)
      ) {
        highlight.push({
          port: eachPort,
        });
      }
    });

    if (highlight.length > 0) {
      this.setState({ highlightDupes: highlight });
    }
  };

  renderWaringMessage = () => {
    if (this.state.highlightDupes?.length == 0) {
      return null;
    }

    return (
      <View
        style={{
          backgroundColor: colors.lighestGray,
          borderRadius: 10,
          padding: 10,
          marginTop: 25,
        }}
      >
        <NtText
          style={{ color: colors.lighGray, fontSize: 15, fontWeight: "600" }}
        >
          Attention, some extensions are configured with the same gateway and
          port number.
        </NtText>
      </View>
    );
  };

  renderViewGateway = () => {
    return (
      <NtTouchableEffect
        style={{
          backgroundColor: colors.nettalkBlue,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 6,
          paddingBottom: 6,
          borderRadius: 15,
        }}
        onPress={() => {
          const url = `http://10.26.${this.context.user.location.octet}.${
            80 + parseInt(this.props.route?.params?.gateway)
          }`;
          Logger("Will load the following URL: ", url);
          window.open(url);
        }}
      >
        <NtText style={{ fontSize: 14, fontWeight: "600", color: "white" }}>
          View Gateway
        </NtText>
      </NtTouchableEffect>
    );
  };

  renderRow = (item, index) => {
    return (
      <NtGatwayExtensionItem
        key={index}
        item={item}
        highlight={this.state.highlightDupes}
        onGatewayPress={(gateway) => {
          this.props.navigation.navigate(routes.gateway, { gateway: gateway });
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={`Gateway ${this.props.route?.params?.gateway}`}
        showNavigation={false}
        showFooter={true}
        showBackButton={true}
      >
        {this.renderWaringMessage()}
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 25 }}
        >
          {this.renderViewGateway()}
          <NtText style={[styles.title, { marginLeft: 15 }]}>
            Records Found{" "}
            <NtText style={[styles.title, { fontWeight: "700" }]}>
              {this.state.master?.length}
            </NtText>
          </NtText>
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Extensions"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.skip = this.skip + this.pageSize;
                  this.handleExtensionsFetch();
                }}
              />
            </View>
          }
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.lighGray,
    fontWeight: "500",
    fontSize: 15,
  },
});

export default GatewayExtensionController;
