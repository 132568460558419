//https://reactnavigation.org/docs/configuring-links/#passing-params
//important links: https://reactnavigation.org/blog/2020/05/16/web-support/

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { View, StyleSheet } from "react-native";
import NoFoundController from "../common/controllers/NoFoundController";
import routes from "./routes";
import DashboardController from "../common/controllers/DashboardController";
import RatesController from "../rates/controllers/RatesController";
import ExtensionUploadController from "../extensions/controllers/ExtensionUploadController";
import FeatureCodeController from "../common/controllers/FeatureCodeController";
import CreateExtensionController from "../extensions/controllers/CreateExtensionController";
import ExtensionReportController from "../extensions/controllers/ExtensionReportController";
import GatewayExtensionController from "../extensions/controllers/GatewayExtensionController";
import LinkController from "../common/controllers/LinkController";
import AppAccessController from "../extensions/controllers/AppAccessController";
import GatewayReportController from "../extensions/controllers/GatewayReportController";

const Stack = createNativeStackNavigator();

function AppNavigator({ onControllerChanged, initialRoute }) {
  return (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenListeners={{
        state: (e) => {
          const controller =
            e.data.state.routes[e.data.state.routes.length - 1].name;
          if (onControllerChanged) {
            onControllerChanged(controller);
          }
        },
      }}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name={routes.dashboard}
        component={DashboardController}
        options={{ title: "HA Admin | Dashbaord" }}
      />

      <Stack.Screen
        name={routes.rates}
        component={RatesController}
        options={{ title: "HA Admin | Rates" }}
      />

      <Stack.Screen
        name={routes.upload}
        component={ExtensionUploadController}
        options={{ title: "HA Admin | Upload" }}
      />

      <Stack.Screen
        name={routes.extensioncreate}
        component={CreateExtensionController}
        options={{ title: "HA Admin | Create Extension" }}
      />

      <Stack.Screen
        name={routes.extensionreport}
        component={ExtensionReportController}
        options={{ title: "HA Admin | Report" }}
      />

      <Stack.Screen
        name={routes.gatewayreport}
        component={GatewayReportController}
        options={{ title: "HA Admin | Report" }}
      />

      <Stack.Screen
        name={routes.gateway}
        component={GatewayExtensionController}
        options={{ title: "HA Admin | Gateway Extensions" }}
      />

      <Stack.Screen
        name={routes.links}
        component={LinkController}
        options={{ title: "HA Admin | Links" }}
      />

      <Stack.Screen
        name={routes.appaccess}
        component={AppAccessController}
        options={{ title: "HA Admin | App Access" }}
      />

      <Stack.Screen
        name={routes.featurecode}
        component={FeatureCodeController}
        options={{ title: "HA Admin | Feature Code" }}
      />

      <Stack.Screen
        name={routes.notFound}
        component={NoFoundController}
        options={{ title: "HA Admin | No Found" }}
      />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default AppNavigator;
