import React, { useEffect, useContext, useState } from "react";
import { View, StyleSheet } from "react-native";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import { fetchExtensions } from "../../api/extension";
import UserContext from "../../context/UserContext";
import settings from "../../config/settings";
import Logger from "../utils/Logger";

function NtDashboardExtension({}) {
  const [extensions, setExtensions] = useState(null);
  const [loading, setLoading] = useState(false);

  const context = useContext(UserContext);

  const loadExtensions = async () => {
    setLoading(true);
    const response = await fetchExtensions(
      context?.user?.location?.domain,
      1,
      10000,
      settings.physicalExtensionPrefix
    );
    if (response.ok) {
      setExtensions(response.data?.extensions);
    }

    setLoading(false);
  };

  const handleFilter = (list, type) => {
    if (type == "room") {
      return list.filter(
        (each) =>
          each.description?.match(/\d+,\d+,\d+,\d+/) &&
          each.extension < 999990000
      );
    } else if (type == "ip") {
      return list.filter(
        (each) =>
          !each.description?.match(/\d+,\d+,\d+,\d+/) &&
          each.extension < 999990000
      );
    } else if (type == "tmp") {
      return list.filter((each) => each.extension >= 999990000);
    } else if (type == "all") {
      return list.filter((each) => each.extension < 999990000);
    } else if (type == "unasign") {
      return list.filter(
        (each) =>
          each.description?.match(/\d+,\d+,\d+,\d+/) &&
          each.extension < 999990000 &&
          each.description?.split(",")[0] == "0"
      );
    } else if (type == "issues") {
      let rooms = list.filter((each) =>
        each.description?.match(/\d+,\d+,\d+,\d+/)
      );

      //will sort...
      rooms = rooms.sort(
        (a, b) => a.description?.split(",")[0] - b.description?.split(",")[0]
      );

      //will first remove all the
      let issuesFound = [];
      rooms.map((each, index) => {
        const eachGateway = each?.description?.split(",")[0];
        const eachPort = each?.description?.split(",")[1];

        if (eachGateway != 0) {
          let dupes = rooms.filter(
            (all) =>
              eachPort == all.description?.split(",")[1] &&
              eachGateway == all.description?.split(",")[0]
          );

          if (
            dupes?.length > 1 &&
            !issuesFound.find(
              (each) => each.gateway == eachGateway && each.port == eachPort
            )
          ) {
            issuesFound.push({
              gateway: eachGateway,
              port: eachPort,
              items: dupes,
            });
          }
        }
      });
      return issuesFound;
    }
  };

  useEffect(() => {
    loadExtensions();
  }, []);

  return (
    <View style={styles.container}>
      {loading && <NtActivityIndicator color={colors.darker} size="small" />}
      {!loading && extensions && (
        <View>
          <NtText style={styles.title}>
            Extensions ({handleFilter(extensions, "all")?.length})
          </NtText>

          <NtText style={[styles.totals, { marginTop: 15 }]}>
            Room Phones: {handleFilter(extensions, "room")?.length}
          </NtText>
          <NtText style={[styles.totals, { marginTop: 2 }]}>
            IP Phones: {handleFilter(extensions, "ip")?.length}
          </NtText>

          <NtText style={[styles.totals, { marginTop: 2 }]}>
            Temporary: {handleFilter(extensions, "tmp")?.length}
          </NtText>
          <NtText style={[styles.totals, { marginTop: 2 }]}>
            Unasigned: {handleFilter(extensions, "unasign")?.length}
          </NtText>
          <NtText
            style={[
              styles.totals,
              { marginTop: 2 },
              handleFilter(extensions, "issues").length > 0
                ? { color: "red" }
                : {},
            ]}
          >
            Issues: {handleFilter(extensions, "issues")?.length}
          </NtText>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lighestGray,
    padding: 20,
    borderRadius: 10,
  },
  title: {
    fontSize: 16,
    color: colors.darker,
    fontWeight: "600",
  },
  totals: {
    fontSize: 14,
    fontWeight: "600",
    color: colors.lighGray,
  },
});

export default NtDashboardExtension;
