import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtDevider from "../../components/NtDevider";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import Logger from "../../common/utils/Logger";

function NtGatwayExtensionItem({ item, highlight }) {
  const [showDescription, setShowDescription] = useState(false);

  const port = item?.description?.split(",")[1];

  const isHightlight = () => {
    return highlight?.find((each) => each.port == port);
  };

  const renderDesctipion = () => {
    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtTouchableEffect
          onPress={() => {
            setShowDescription(!showDescription);
          }}
        >
          <NtText
            style={[
              styles.gateway,
              { backgroundColor: isHightlight() ? "red" : colors.nettalkBlue },
            ]}
          >
            Port = {port}
          </NtText>
        </NtTouchableEffect>

        {showDescription && (
          <NtText style={[styles.description, { marginLeft: 10 }]}>
            {item?.description}
          </NtText>
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", padding: 6, alignItems: "center" }}>
        <View style={{ flex: 0.5, flexDirection: "row", alignItems: "center" }}>
          {isHightlight() && (
            <NtMaterialIcon
              name="information-outline"
              containerStyle={{ marginRight: 10 }}
            />
          )}

          <NtText style={styles.extension}>
            {item.extension?.substring(4)}
          </NtText>
        </View>

        <View style={{ flex: 1 }}>
          <NtText style={styles.name}>{item.effective_caller_id_name}</NtText>
        </View>

        {/* description... */}
        <View style={{ flex: 1 }}>{renderDesctipion()}</View>
      </View>
      <NtDevider />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  extension: {
    fontSize: 15,
    fontWeight: "600",
    color: colors.darkest,
  },
  name: {
    fontSize: 15,
    fontWeight: "500",
    color: colors.lighGray,
  },
  description: {
    fontSize: 15,
    fontWeight: "500",
    color: colors.lighGray,
  },
  gateway: {
    fontSize: 13,
    fontWeight: "600",
    backgroundColor: colors.nettalkBlue,
    color: "white",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 30,
  },
});

export default NtGatwayExtensionItem;
