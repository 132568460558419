export const linking = {
  config: {
    screens: {
      dashboard: "/dashboard",
      auth: "/auth",
      authcode: "/authcode/:extension",
      rates: "/rates",
      upload: "/upload",
      extensioncreate: "/extensioncreate",
      extensionreport: "/extensionreport",
      gatewayreport: "/gatewayreport",
      appaccess: "/appaccess",
      links: "/links",
      featurecode: "/featurecode",
      gateway: "/gateway/:gateway",
      notfound: "*",
    },
  },
};

const routes = {
  dashboard: "dashboard",
  rates: "rates",
  auth: "auth",
  authcode: "authcode",
  upload: "upload",
  extensioncreate: "extensioncreate",
  extensionreport: "extensionreport",
  gatewayreport: "gatewayreport",
  appaccess: "appaccess",
  links: "links",
  featurecode: "featurecode",
  gateway: "gateway",
  notFound: "notfound",
};

export default routes;
