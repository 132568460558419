import React from "react";
import { View, StyleSheet } from "react-native";
import routes from "../../navigation/routes";
import NavigationBar from "../components/NavigationBar";
import Logger from "../../common/utils/Logger";
import AuthNavigator from "../../navigation/AuthNavigator";
import RootController from "./RootController";
import NtFooterBar from "../components/NtFooterBar";
import settings from "../../config/settings";

class RootAuthController extends RootController {
  state = {
    currentController: null,
    isMobile: false,
  };

  navItems = [];

  componentDidMount() {
    super.componentDidMount();
  }

  handleLogout = () => {
    console.log("Signing out");
    this.context.updateUser(null);
  };

  showNavigation = () => {
    return (
      this.state.currentController !== routes.notFound &&
      this.state.currentController !== routes.auth &&
      this.state.currentController !== routes.authcode
    );
  };

  render() {
    return (
      <View style={styles.container}>
        {this.showNavigation() && (
          <NavigationBar
            navItems={this.navItems}
            currentController={this.state.currentController}
            onNavPress={(route) => {
              this.handleNavPress(route);
            }}
            onLogout={() => this.handleLogout()}
          />
        )}

        <AuthNavigator
          onControllerChanged={(controller) => {
            Logger("Current controller: ", controller);
            this.setState({ currentController: controller });
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flex: 1,
  },
});

export default RootAuthController;
