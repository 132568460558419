import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, NativeModules } from "react-native";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtDevider from "../../components/NtDevider";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import locationsdata from "../../root/data/locationsdata";
const { NettalkModule } = NativeModules;

function NtLoginHALocationPicker({ onPress, value }) {
  const renderRow = (item, index) => {
    return (
      <NtTouchableEffect
        key={index}
        onPress={() => {
          if (onPress) {
            onPress(item);
          }
        }}
      >
        <View
          style={{
            paddingTop: 15,
            paddingBottom: 15,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 1 }}>
            <NtText style={styles.rowTitle}>{item.name}</NtText>
            <NtText style={styles.rowSubtitle}>{item.code}</NtText>
          </View>

          {item.name == value && (
            <NtMaterialIcon name="check" color={colors.darkest} />
          )}
        </View>

        <NtDevider />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <NtText style={[styles.title, { marginRight: 10 }]}>
            Select Location
          </NtText>
        </View>

        {locationsdata?.map((each, index) => renderRow(each, index))}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 300,
    padding: 15,
    maxHeight: 450,
  },

  title: {
    color: colors.darkest,
    fontSize: 17,
    fontWeight: "700",
  },
  rowTitle: {
    color: colors.darkest,
    fontSize: 14,
    fontWeight: "600",
  },
  rowSubtitle: {
    color: colors.darkest,
    fontSize: 12,
    fontWeight: "400",
  },
  error: {
    color: "red",
    fontSize: 12,
    fontWeight: "400",
  },
});

export default NtLoginHALocationPicker;
