const settings = {
  rateMultiplier: 1.4,
  rateThreshold: 0.25,

  apiUrl: "http://fph.hap.ntlk.co:31184/api/v1",
  apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",

  pageSizeLoad: 25,
  mobileSize: 880,

  defaultScoreLayout: "default",

  mobileExtensionPrefix: "9998",
  physicalExtensionPrefix: "9999",
};

export default settings;
